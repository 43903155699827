<template>
  <div>
    <v-app-bar
      id="home-app-bar"
      app
      elevation="1"
      elevate-on-scroll
      height="80"
    >
      <base-img
        :src="require('@/assets/logo.svg')"
        class="mr-3 hidden-xs-only"
        contain
        max-width="320"
        width="100%"
      />
      <base-img
        :src="require('@/assets/logo.svg')"
        class="mr-3 hidden-sm-and-up"
        contain
        max-width="240"
        width="100%"
      />

      <v-spacer />

      <v-menu>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            text
            color="#0077B6"
            class="font-weight-bold"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-menu</v-icon>
            <span class="ml-2 d-none d-sm-flex">Jump to...</span>
          </v-btn>
        </template>

        <v-list>
          <v-list-item v-for="(item, i) in items" :key="i" @click="clicked(item)" v-show="!item.hideFromMenu">
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-btn text color="#0077B6" class="font-weight-bold" @click="contactUs()">
        <v-icon>mdi-email</v-icon>
        <span class="ml-2 d-none d-sm-flex">Contact Us</span>
      </v-btn>
    </v-app-bar>
  </div>
</template>

<script>
import sectionsJson from "@/sections.json";
const SECTIONS = sectionsJson.filter(sec => !sec.disabled || location.port);
export default {
  name: "HomeAppBar",

  data: () => ({
    drawer: null,
    items: SECTIONS
  }),

  methods: {
    contactUs() {
      window.location.href = "mailto:BradfordAreaCommission@gmail.com";
    },
    clicked(item) {
      window.$app.route(item);
    }
  },
};
</script>

<style lang="sass">
#home-app-bar
  .v-tabs-slider
    max-width: 24px
    margin: 0 auto

  .v-tab
    &::before
      display: none
</style>
